import { animated, easings, useTrail } from 'react-spring'

export function AnimateChildren({ children, layoutClassName = undefined }) {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { duration: 1000, mass: 100, friction: 250, easing: easings.easeOutExpo },
    from: { opacity: 0, y: 20 },
    to: { opacity: 1, y: 0 },
    delay: 350
  })

  return (
    <React.Fragment>
      {trail.map((style, index) =>
        <animated.div key={index} className={layoutClassName} {... { style }}>
          {items[index]}
        </animated.div>
      )}
    </React.Fragment>
  )
}
