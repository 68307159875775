import KbmBlackAndRedLogo from '/images/logos/kbm/black-and-red.svg'
import KbmWhiteAndRedLogo from '/images/logos/kbm/white-and-red.svg'

import AffilipsBlackAndBlueLogo from '/images/logos/affilips/black-and-blue.svg'
import AffilipsWhiteAndBlueLogo from '/images/logos/affilips/white-and-blue.svg'

import KbmAffilipsBlackAndPurpleLogo from '/images/logos/kbm-affilips/black-and-purple.svg'
import KbmAffilipsWhiteAndPurpleLogo from '/images/logos/kbm-affilips/white-and-purple.svg'

export function LogoKbmBlackRed({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={KbmBlackAndRedLogo} {... { layoutClassName }} />
  )
}

export function LogoKbmWhiteRed({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={KbmWhiteAndRedLogo} {... { layoutClassName }} />
  )
}

export function LogoAffilipsBlackBlue({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={AffilipsBlackAndBlueLogo} {... { layoutClassName }} />
  )
}

export function LogoAffilipsWhiteBlue({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={AffilipsWhiteAndBlueLogo} {... { layoutClassName }} />
  )
}

export function LogoKbmAffilipsBlackPurple({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={KbmAffilipsBlackAndPurpleLogo} {... { layoutClassName }} />
  )
}

export function LogoKbmAffilipsWhitePurple({ layoutClassName = undefined }) {
  return (
    <LogoBase logo={KbmAffilipsWhiteAndPurpleLogo} {... { layoutClassName }} />
  )
}

function LogoBase({ logo, layoutClassName }) {
  return (
    <img src={logo} role="presentation" alt="" className={layoutClassName} />
  )
}
