import styles from './CenteredContainer.css'

export function CenteredContainerLg({ children, as = undefined, layoutClassName = undefined }) {
  return (
    <BaseCenteredContainer size="lg" {... { as, layoutClassName }}>
      {children}
    </BaseCenteredContainer>
  )
}

function BaseCenteredContainer({ children, size, as = undefined, layoutClassName = undefined }) {
  const Component = as ?? 'div'
  return (
    // eslint-disable-next-line @kaliber/layout-class-name
    <Component className={cx(styles.componentBaseCenteredContainer, layoutClassName)}>
      <div className={cx(styles[size])}>{children}</div>
    </Component>
  )
}
