import styles from './Section.css'

export function SectionWhite({ children, dataX = undefined, layoutClassName = undefined }) {
  return <SectionBase backgroundColor="white" {... { children, dataX, layoutClassName }} />
}

export function SectionGray({ children, dataX = undefined, layoutClassName = undefined }) {
  return <SectionBase backgroundColor="gray" {... { children, dataX, layoutClassName }} />
}

export function Section({ children, dataX = undefined, layoutClassName = undefined }) {
  return <SectionBase {... { children, dataX, layoutClassName }} />
}

function SectionBase({ children, layoutClassName, dataX = undefined, backgroundColor = undefined }) {
  return <section data-x={dataX} className={cx(styles.componentBase, layoutClassName, styles[backgroundColor])}>{children}</section>
}
