import styles from './Link.css'

export { LinkBase as Link }

export function LinkText({ to, dataX, children, onClick = undefined, target = undefined, rel = undefined, label = undefined }) {
  return <LinkBase className={styles.componentText} {...{ to, dataX, onClick, children, target, rel, label }} />
}

function LinkBase({ to, dataX, children, className, onClick, target, rel, label = undefined }) {
  const safeRel = target === '_blank' ? `${rel || ''} noopener noreferrer` : rel
  return <a href={to} data-x={dataX} rel={safeRel} aria-label={label} className={cx(styles.componentBase, className)} {...{ onClick, target }}>{children}</a>
}
