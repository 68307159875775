import { LogoKbmAffilipsWhitePurple, LogoKbmWhiteRed, LogoAffilipsWhiteBlue } from '/components/pageOnly/Logo'
import { AnimateChildren } from '/machinery/AnimateChildren'

import styles from './Header.css'

export function Header({ layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <AnimateChildren layoutClassName={styles.animatedLogoContainer}>
        <LogoKbmAffilipsWhitePurple />
        <LogoKbmWhiteRed />
        <LogoAffilipsWhiteBlue />
      </AnimateChildren>
    </div>
  )
}

