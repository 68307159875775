import styles from './Text.css'

export function TextSm({ children, layoutClassName = undefined }) {
  return <TextBase as="p" className={styles.componentSm} {...{ children, layoutClassName }} />
}

export function TextMd({ children, layoutClassName = undefined }) {
  return <TextBase as="p" className={styles.componentMd} {...{ children, layoutClassName }} />
}

export function TextLg({ children, as = undefined, layoutClassName = undefined }) {
  return <TextBase className={styles.componentLg} {...{ children, as, layoutClassName }} />
}

export function TextXl({ children, as = undefined, layoutClassName = undefined }) {
  return <TextBase className={styles.componentXl} {...{ children, as, layoutClassName }} />
}

export function TextXxl({ children, as = undefined, layoutClassName = undefined }) {
  return <TextBase className={styles.componentXxl} {...{ children, as, layoutClassName }} />
}

function TextBase({ children, as, className, layoutClassName }) {
  const Component = as ?? 'p'

  // eslint-disable-next-line @kaliber/layout-class-name
  return <Component className={cx(styles.componentBase, className, layoutClassName)} {...{ children }} />
}
